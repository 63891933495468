<template lang="pug">
  v-container.fill-height
    v-row(
      align="center"
      justify="center"
    )
      v-col(cols="12" sm="8" md="6")
        v-card.adock-card-with-header
          v-toolbar(
            color="primary"
            dark
            flat
          )
            v-toolbar-title
              h4 Nouveau mot de passe
          v-container.pa-6
            v-form(
              v-model="isValid"
              ref="form"
              @submit="submit"
            )
              v-alert.adock-show-linebreaks(
                v-if="errorMessage"
                type="error"
                outlined
                text
              ) {{ errorMessage }}
              v-text-field(
                label="Adresse e-mail"
                type="email"
                v-model="email"
                :disabled="true"
              )
              v-text-field(
                label="Nouveau mot de passe"
                type="password"
                v-model="password"
                :rules="passwordRules"
                :append-icon="isPlainPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (isPlainPassword = !isPlainPassword)"
                :type="isPlainPassword ? 'text': 'password'"
                counter
                validate-on-blur
                :error-messages="fieldErrors.password"
              )
              v-row
                v-col(align="right")
                  v-btn(
                    color="primary"
                    @click="submit"
                    :disabled="isDisabled"
                    type="submit"
                  ) Modifier le mot de passe
</template>

<script>
import api from "@/api";
import { passwordRules, scrollToErrorsMixin } from "@/mixins";

export default {
  mixins: [scrollToErrorsMixin],

  data() {
    return {
      password: "",
      isValid: false,
      isPlainPassword: false,
      errorMessage: "",
      fieldErrors: {},
    };
  },

  props: {
    email: {
      type: String,
      default: null,
      required: false,
    },
    token: {
      type: String,
      default: null,
      required: false,
    },
  },

  created() {
    this.passwordRules = passwordRules;
  },

  computed: {
    isDisabled() {
      // Only for submit button
      const statuses = [];
      this.passwordRules.forEach((rule) => statuses.push(rule(this.password)));
      return !statuses.every((v) => v === true);
    },
  },

  methods: {
    async submit(e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        const response = await api.post(api.passwordResetUrl, {
          email: this.email,
          token: this.token,
          password: this.password,
        });
        if (response.status === 200) {
          this.errorMessage = "";
          this.$store.commit("MESSAGE_ADD", {
            message: `Le mot de passe du compte « ${this.email} » a été modifié, vous pouvez maintenant vous identifier.`,
          });
          this.$router.push({
            name: "accounts__login",
          });
        } else {
          this.setErrorsAndScrollTo(response.data, this.$refs.form);
        }
      }
    },
  },
};
</script>
